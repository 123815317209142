<template>
  <div>
    <b-title>
      <check-list-icon class="icon" />
      {{ $t("subscribe.title") }}
    </b-title>
    <b-information>
      <div v-html="$t('subscribe.information')"></div>
    </b-information>
    <b-container class="d-flex flex-wrap justify-content-center my-5">
      <b-btn :to="$localePath({ name: 'FormDownload' })" class="my-2 mx-2">
        {{ $t("subscribe.download_forms") }}
      </b-btn>
      <b-btn :to="$localePath({ name: 'FormSubmission' })" class="my-2 mx-2">
        {{ $t("subscribe.submit_forms") }}
      </b-btn>
    </b-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CheckListIcon from "@/components/icon/CheckListIcon.vue";

export default defineComponent({
  components: { CheckListIcon },
});
</script>

<style lang="scss" scoped>
.check-icon {
  width: 30px;
  margin-right: 10px;
  flex-shrink: 0;
}
</style>
