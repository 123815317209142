<template>
  <svg
    id="checklist-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M274.827,213.911H126.655c-4.143,0-7.5,3.358-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h148.172c4.142,0,7.5-3.357,7.5-7.5
			C282.327,217.269,278.969,213.911,274.827,213.911z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M393.557,202.331c-2.064-3.59-6.649-4.827-10.24-2.763l-35.106,20.188l3.225-11.691c1.101-3.993-1.243-8.123-5.236-9.224
			c-3.996-1.102-8.123,1.243-9.224,5.236l-4.104,14.877c-1.477,5.354,0.406,11.011,4.796,14.412
			c4.17,3.23,10.333,3.815,15.155,1.041l37.971-21.835C394.385,210.507,395.622,205.922,393.557,202.331z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M274.827,271.088H126.655c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h148.172c4.142,0,7.5-3.357,7.5-7.5
			C282.327,274.446,278.969,271.088,274.827,271.088z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M393.557,259.509c-2.064-3.591-6.649-4.828-10.24-2.763l-35.106,20.188l3.225-11.691c1.102-3.993-1.243-8.123-5.236-9.224
			c-3.996-1.102-8.123,1.243-9.224,5.236l-4.104,14.877c-1.477,5.354,0.406,11.011,4.797,14.412
			c4.183,3.24,10.318,3.822,15.155,1.041l37.971-21.835C394.385,267.685,395.622,263.1,393.557,259.509z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M274.827,328.265H126.655c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h148.172c4.142,0,7.5-3.358,7.5-7.5
			C282.327,331.623,278.969,328.265,274.827,328.265z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M393.557,316.686c-2.064-3.59-6.649-4.828-10.24-2.763l-35.106,20.188l3.225-11.692c1.102-3.993-1.243-8.123-5.236-9.224
			c-3.996-1.102-8.123,1.243-9.224,5.236l-4.104,14.877c-1.477,5.354,0.406,11.011,4.797,14.413
			c4.355,3.373,10.381,3.785,15.154,1.041l37.971-21.835C394.385,324.862,395.622,320.277,393.557,316.686z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M274.827,385.442H126.655c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h148.172c4.142,0,7.5-3.358,7.5-7.5
			C282.327,388.8,278.969,385.442,274.827,385.442z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M393.557,373.863c-2.064-3.591-6.649-4.828-10.24-2.763l-35.106,20.188l3.225-11.692c1.101-3.993-1.243-8.123-5.236-9.224
			c-3.996-1.102-8.123,1.242-9.224,5.235l-4.104,14.877c-1.477,5.354,0.406,11.012,4.797,14.413
			c4.354,3.373,10.379,3.787,15.154,1.041l37.971-21.835C394.385,382.038,395.622,377.454,393.557,373.863z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M412.992,0c0,0-206.554,0-206.653,0c-1.963,0-3.912,0.81-5.303,2.196c0,0-132.002,132.002-132.03,132.031
			c-1.367,1.39-2.193,3.351-2.193,5.299v276.282c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V147.026h95.08
			c16.553,0,30.598-10.942,35.283-25.973c1.105-3.545,1.664-7.262,1.664-10.975V15h199.153c9.481,0,17.194,7.718,17.194,17.204
			v101.604c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V32.204C445.187,14.447,430.744,0,412.992,0z M198.84,110.079
			c0,11.9-9.97,21.947-21.947,21.947H92.42l106.42-106.42C198.84,25.606,198.84,110.076,198.84,110.079z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M437.686,156.309c-4.142,0-7.5,3.358-7.5,7.5v315.997c0,9.481-7.713,17.194-17.194,17.194H99.008
			c-9.481,0-17.194-7.713-17.194-17.194v-33.997c-0.001-4.142-3.359-7.5-7.501-7.5c-4.142,0-7.5,3.358-7.5,7.5v33.997
			c0,17.752,14.442,32.194,32.194,32.194h313.985c17.752,0,32.194-14.442,32.194-32.194V163.809
			C445.186,159.667,441.828,156.309,437.686,156.309z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
